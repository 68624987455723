.tableHead{
    background-color: rgb(23, 43, 77);
}
.tableHeadLabel{
    color: #ffff !important;
}
.tableHeadLabel:hover{
    color: rgb(65, 245, 101) !important;
}
.light{
    color: black;
}
.dark{
    color: #ffff;
}
.light > .searchBox:hover{
    background-color: #f7f6f5;
}
.dark > .searchBox:hover{
    background-color: #4c4747;
}

.searchInput {
    // width: 50%;
    margin-left: 20px;
}
.cardSearch {
    width: 500px;
    margin-left: 15px;
}
//sort table
.css-1kdmlze-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
    color: red !important;
}
.css-1kdmlze-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: red !important;
}
.css-tnyant-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color:#ffff !important
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color:#ffff !important
}
// .css-28ckhe-MuiPaper-root-MuiAppBar-root {
//     background-color: #121212 !important;
// }

@media only screen and (min-width: 0px) and (max-width: 800px) {
    .cardSearch {
        width: 350px;
        margin-left: 5px;
    }

    .searchInput {
        width: 340px;
        margin-left: 10px;
    }
}