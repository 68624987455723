
// .rbc-date-cell {
//     z-index: 9999999;
//     color: black;
// }

.caledar .rbc-row {
    cursor: default;
}

.caledar .rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid #ddd;
    cursor: default;
}

// .rbc-month-header {
//     color: red;
// }
// .weekend >.rbc-month-header {
//     background-color: #fcf3ed;
//     color: black;
// }
.Th7 {
    background-color: #fff9c4;
}

.CN {
    background-color: #b9f6ca;
}

.fullname {
    font-weight: bold;
    background-color: #eceff1;
    position: sticky;
    left: 0;
    z-index: 1;
}


.fullWorkDay {
    background-color: #e0fcf1;
}

.haftWorkDay {
    background-color: #e0fcf1;
}

.vacationDay {
    background-color: #e0fcf1;
}

.studyWorkDay {
    background-color: #e0fcf1;

}

.maternity {
    background-color: #e0fcf1;
}

.remote {
    background-color: #e0fcf1;
}

.noSalary {
    background-color: #e0fcf1;
}

.totalWork {
    background-color: #fbe9e7;
    color: red;
    font-weight: bold;
    position: sticky;
    right: 0;
    z-index: 1;
}

// 
.TableRow {
    background-color: #ffff;
}

.table {
    border-radius: 5px;
}

.TableSortLabelTimekeeping {
    pointer-events: none !important;
}


.TableRow:hover {

    background-color: #f5f5f5 !important;

    .fullname {
        color: red;
        background-color: #f2f5f7;
    }

    .CN {
        background-color: #e0fde8;
    }

    .Th7 {
        background-color: #f9f6dd;
    }

    .fullWorkDay {
        background-color: #f1fbf7;
    }

    .haftWorkDay {
        background-color: #f1fbf7;
    }

    .vacationDay {
        background-color: #f1fbf7;
    }

    .studyWorkDay {
        background-color: #f1fbf7;

    }

    .maternity {
        background-color: #f1fbf7;
    }

    .remote {
        background-color: #f1fbf7;
    }

    .noSalary {
        background-color: #f1fbf7;
    }

    .totalWork {
        background-color: #fbefee;
    }
}