.rbc-event:focus {
    outline: red;
}

// .rbc-date-cell {
//     color: black;
// }

  
// .rbc-date-cell:not(.rbc-event) {
//     color: red;
//   }
  

.rbc-button-link {
    pointer-events: none !important;
}


.button {
    background-color: blue;
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.active {
    background-color: red;
}

.rbc-month-view {
    cursor: pointer !important;
}

.isActive .rbc-month-view {
    pointer-events: none !important;
    cursor: default !important;
}


.rbc-off-range-bg {
    cursor: default;
    z-index: 9;
    background-color: #bfc5c8 !important;

}

.weekend {
    background-color: #f59e64;
}


.rbc-toolbar {
    width: 100%;
}

.lableMonth {
    margin-top: -190px;
    width: 25%;
    margin-left: 37%;
}

.buttonNextPrev {
    border-color: rgb(243, 231, 231);
    background: white;
    height: 30px;
}

:hover.buttonNextPrev {
    background: rgb(243, 231, 231);
}

.rbc-row {
    margin-top: 0.65% !important;
}

// .background-event {
    // background-color: #ffff;
// }

.calendar {
    pointer-events: none !important;
}

.rbc-today {
    // background-color: #eaf6ff;
    background-color: #49aefc;
}
  

@media only screen and (min-width: 0px) and (max-width: 800px) {
    .rbc-toolbar {
        width: 55%;
        margin-left: 6%;
    }

    .lableMonth {
        margin-top: -140px;
        width: 55%;
        margin-left: 25%;
    }

}