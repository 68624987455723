.textField {
    pointer-events: none !important;

}

.divDragDropContext {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    margin-top:70px;
}
