.css-14kc7eg-MuiButtonBase-root-MuiTableSortLabel-root:hover {
    color: rgb(151, 251, 0);
}

// checkbox
.css-1vptd2f-MuiButtonBase-root-MuiCheckbox-root {
    color: black;
    background-color: white;
}

.css-1vptd2f-MuiButtonBase-root-MuiCheckbox-root:hover {
    color: #008cff;
    background-color: rgb(242, 232, 232);
}

.css-1vptd2f-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1vptd2f-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #008cff;
}

//deploy checkbox
.css-ii4ec0 {
    color: black;
    background-color: white;
}

.css-ii4ec0:hover {
    color: #008cff;
    background-color: rgb(242, 232, 232);
}

.css-ii4ec0.Mui-checked,
.css-ii4ec0.MuiCheckbox-indeterminate {
    color: #008cff;
}


// header
.css-14kc7eg-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
    color: white;
    font-size: large;
}

.css-14kc7eg-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: white;
}

// deploy
.css-16fps67.Mui-active {
    color: white;
    font-size: large;
}

.css-16fps67.Mui-active .MuiTableSortLabel-icon {
    color: white;
}

.css-16fps67:hover {
    color: rgb(151, 251, 0);
}

//Sidebar
.css-bpi7ug-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    font-weight: 600;
}

.css-bpi7ug-MuiButtonBase-root-MuiListItemButton-root.Mui-selected .MuiListItemIcon-root {
    font-weight: 600;
}

// deploy
.css-17x3icp.Mui-selected {
    font-weight: 600;
}

.css-17x3icp.Mui-selected .MuiListItemIcon-root {
    font-weight: 600;
}

.cardSearch {
    width: 50%;
}

.searchInput {
    width: 50%;
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
    .cardSearch {
        width: 85%;
        margin-left: -5px;
    }

    .searchInput {
        width: 340px;
        margin-left: 10px;
    }
}