$red-background-color: #f50057;
$blue-background-color: #3d5afe;
$red-text-color: white;
$blue-text-color: white;

$red-background-color-hover: #f83a7c;
$blue-background-color-hover: #5d74f9;
$no-background-color-hover:rgb(225, 223, 223);




.red-background {
    background-color: $red-background-color;
    color: $red-text-color;
    padding: 4px;
    cursor: pointer;
  }
  .red-background:hover {
    background-color: $red-background-color-hover;
  }
  .blue-background {
    background-color: $blue-background-color;
    color: $blue-text-color;
    padding: 4px;
    cursor: pointer;
  }
  .blue-background:hover {
    background-color: $blue-background-color-hover;
  }
.no-background{
    padding: 4px;
    cursor: pointer;
}
.no-background:hover{
    background-color: $no-background-color-hover;
    color: black;
}